<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import BudgetVsReport from "./BudgetVsReport.vue";
import DoualChartReport from "./DoualChartReport.vue";
// import EmployeeFiles from "./EmployeeFiles/index.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    BudgetVsReport,
    DoualChartReport,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('reports.budget_is_actual')"
    />

    <!-- start personal informations -->

    <b-tabs
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("budget-vs-report.tabs.report") }}
          </span>
        </template>
        <BudgetVsReport />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("budget-vs-report.tabs.chart") }}
          </span>
        </template>
        <DoualChartReport />
      </b-tab>
    </b-tabs>

    <!-- end personal informations -->
  </Layout>
</template>
<style scoped></style>
