<script>
import appConfig from "@/app.config";
import ReportsHeader from "@/components/reports-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    ReportsHeader,
  },
  data() {
    return {
      accounts: [],

      // appprices:[]
    };
  },
  methods: {
    getReport() {
      this.http.post("transactions/budget-report", {}).then((res) => {
        this.accounts = [
          ...res.data[0]?.data?.expenses,
          ...res.data[0]?.data?.revenues,
        ];
        this.accounts.forEach((account) => {
          account.variance = 0;
          account.variance +=
            parseFloat(account?.budget ? account.budget : 0) -
            parseFloat(account?.actual ? account.actual : 0);
          // ammunt

          // percent
          account.variance_percent = 0;
          account.variance_percent +=
            account.variance / parseFloat(account.budget ? account.budget : 1);
          if (account.variance == account.budget) {
            account.variance_percent -= 1;
          }
        });
        console.log(this.accounts);
      });
    },
    changeRemark(budget) {
      this.http
        .put("budget", budget.id, {
          remarks: budget?.remarks,
        })
        .then(() => {
          this.get(this.page);
        });
    },
  },
  created() {
    this.getReport();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <ReportsHeader
            class="mb-2"
            :reportName="$t('reports.budget_is_actual')"
          />
          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-centered table-striped table-bordered table-nowrap align-middle"
            >
              <thead>
                <tr>
                  <th>{{ $t("reports.account_id") }}</th>
                  <th>{{ $t("reports.descr") }}</th>
                  <th>{{ $t("budget-vs-report.budget") }}</th>
                  <th>{{ $t("budget-vs-report.actual") }}</th>
                  <th>{{ $t("budget-vs-report.var_amount") }}</th>
                  <th>{{ $t("budget-vs-report.var_percent") }}</th>
                  <th>{{ $t("budget-vs-report.remarks") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="account in accounts" :key="account">
                  <td>{{ account?.code }}</td>
                  <td>
                    {{
                      $i18n.locale == "ar" ? account?.name : account?.name_en
                    }}
                  </td>
                  <td>
                    {{
                      account?.budget
                        ? parseFloat(account?.budget).toLocaleString()
                        : 0
                    }}
                  </td>
                  <td>
                    {{
                      account?.actual
                        ? parseFloat(account?.actual).toLocaleString()
                        : 0
                    }}
                  </td>
                  <td>
                    {{ account?.variance?.toLocaleString() }}
                  </td>
                  <td>
                    {{
                      (parseFloat(account?.variance_percent) * 100)
                        .toFixed(2)
                        ?.toLocaleString() + " %"
                    }}
                  </td>
                  <td>
                    <textarea
                      :disabled="!account.id"
                      @change="changeRemark(account)"
                      name=""
                      v-model="account.remarks"
                      id=""
                      cols="30"
                      rows="2"
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
