<template>
  <div>
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">
            <ReportsHeader :reportName="$t('reports.budget_is_actual')" />
          </h4>
          <!-- Column Charts -->
          <apexchart
            v-if="this.categories.length > 0"
            class="apex-charts"
            height="350"
            type="bar"
            dir="ltr"
            :series="columnChart.series"
            :options="columnChart.chartOptions"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportsHeader from "@/components/reports-header";
export default {
  data() {
    return {
      getTranslate: this.$i18n.locale,
      categories: [],
      columnChart: {
        series: [
          { name: "planned", data: [] },
          { name: "actual", data: [] },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "45%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          colors: ["#34c38f", "#556ee6", "#f46a6a"],
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: "SDG (thousands)",
              style: {
                fontWeight: "500",
              },
            },
          },
          grid: {
            borderColor: "#f1f1f1",
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "$ " + val + " thousands";
              },
            },
          },
        },
      },
    };
  },
  components: { ReportsHeader },
  methods: {
    getReport() {
      this.http
        .post("transactions/budget-chart", { branch_id: this.branch_id })
        .then((res) => {
          //   this.columnChart = res.data;
          this.categories = res.data.map((item) => item?.name_en);
          this.columnChart.chartOptions.xaxis.categories = this.categories;
          this.columnChart.series = [
            {
              name: "planned",
              data: res.data.map((planned) =>
                planned?.planned ? parseFloat(planned?.planned) : 0
              ),
            },
            {
              name: "actual",
              data: res.data.map((actual) =>
                actual?.actual ? parseFloat(actual?.actual) : 0
              ),
            },
          ];
        });
    },
  },
  created() {
    this.getReport();
  },
};
</script>
